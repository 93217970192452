/* NavBar */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 17px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  transform: rotate(0);
  transition: all 0.5s;
}
.hamburger-top {
  width: 20px;
  left: 2px;
}
.hamburger-middle {
  transform: translateY(7px);
}
.hamburger-bottom {
  transform: translateY(14px);
  width: 20px;
  left: 2px;
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
  width: 24px;
  left: 0px;
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
  width: 24px;
  left: 0px;
}

#menu {
  filter: drop-shadow(0 10px 5px rgb(0 0 0 / 0.25));
}

/* Submit Button */
.btn {
  transform: translate(0);
}

.sarrow {
  position: absolute;
  top: 19px;
  left: 30px;
  transition: 0.5s ease;
}

.barrow {
  position: absolute;
  top: 80px;
  left: 30px;
}

/* Send Text */
.pF {
  animation: move 700ms linear 700ms forwards;
}

/* Small Arrow */
.sarrowN {
  top: -50px;
  transition: all 0.5s ease;
}

/* Big Arrow */
.barrowN {
  animation: sending 700ms linear 700ms forwards;
  top: 15px;
  transition: all 0.5s ease;
}

/* Send Button Animations*/
@keyframes sending {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translate(40px, -60px);
  }
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-18px);
  }
}
