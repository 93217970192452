@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Sen';
  src: local('Sen'), url(./Components/fonts/Sen-Regular.ttf) format('opentype');
}


@layer base {
  body {
    @apply dark:from-[#0E161C] dark:to-[#070b0e] bg-gradient-to-b from-[#fbfdff] to-[#cbd7e0]
  }
}

*{
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}
body{
  font-family: 'Sen', sans-serif;
}